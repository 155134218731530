import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Button, Modal, Form } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import SidebarData from '../Sidebar/SidebarData';
import profile from '../../assets/img/logo.png';
import wallet_icon from '../../assets/img/wallet_icon.png';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdRefresh } from "react-icons/io";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';

export default function Header() {
  const [scrolltopdata, setscrolltopdata] = useState('');
  const [showModal, setShowModal] = useState(false); // State for controlling the modal
  const [showGooglePayModal, setShowGooglePayModal] = useState(false); // State for controlling the Google Pay modal
  const [showPhonePeModal, setShowPhonePeModal] = useState(false); // State for controlling the PhonePe modal
  const [userData, setUserData] = useState(null);
  const [users, setUsers] = useState([]);
  const [gameid, setGameid] = useState(localStorage.getItem('gameid') || '');
  const location = useLocation();
  const [path, setPath] = useState([]);
  const [paytm, setPaytm] = useState("");
  const [Google, setGoogle] = useState("");
  const [phonepay, setphonepay] = useState("");
  const [open, setOpen] = useState(false);
  const [paytmError, setPaytmError] = useState('');
  const [paytmErrorr, setPaytmErrorr] = useState('');
  const [phonepayrr, setphonepayrr] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 1) {
        setscrolltopdata('');
      } else {
        setscrolltopdata('scrolled');
      }
    });
  }, []);

  useEffect(() => {
    const isPlayPage = location.pathname === '/';
  }, [location.pathname]);

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleReload = () => {
    window.scrollTo(0, 0);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowGooglePayModal = () => {
    setShowGooglePayModal(true);
  };
  
  const handleCloseGooglePayModal = () => {
    setShowGooglePayModal(false);
  };

  const handleShowPhonePeModal = () => {
    setShowPhonePeModal(true);
  };
  
  const handleClosePhonePeModal = () => {
    setShowPhonePeModal(false);
  };

  const refreshPage = () => {
    window.location.reload(false);
  };

  const logout = (e) => {
    e.preventDefault();

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will be log out!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Log Out !'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        navigate('/Login');
      }
    });
  }

  useEffect(() => {
    const updatePath = () => {
      const segment = window.location.pathname.split("/");
      setPath(segment);
    };
    updatePath();
    const intervaldId = setInterval(updatePath, 1000);
    return () => {
      clearInterval(intervaldId);
    }
  }, []);

  useEffect(() => {
    const user_id = localStorage.getItem('userid');
    if (user_id == null) {
      window.location.href = '/Login';
    }
    const devid = localStorage.getItem('dev_id');
    if (devid == null) {
      window.location.href = '/Login';
    }

  }, [gameid]);

  useEffect(() => {
    loaduser();
    // loadUserr();
  }, []);

  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);

    var config = {
      method: "POST",
      url: url,
      data: formData,
    };

    axios(config)
      .then(function (response) {
        console.log("API Response:", response);
        const res = response.data;
        const res1 = response.data.success;

        if (res !== undefined) {
          setUserData(res);
          console.warn(res);
        }
        if(res1 == 3){
          
          localStorage.clear();
          navigate('/Login');
      }
      })
      .catch(function (error) {
        console.error("Error fetching profile data: ", error);
      });
  };

  const handleInputChange = (e) => {
    setPaytm(e.target.value);
  };
  const handleInputChanggoogel = (e) => {
    setGoogle(e.target.value);
  };
  const handleInputChangephonepay = (e) => {
    setphonepay(e.target.value);
  };



  const phonepayyyy = async () => {
    // alert("jhgh")
    try {
      const user_id = localStorage.getItem("userid");
      const dev_id = localStorage.getItem("dev_id");
      const url = `${process.env.REACT_APP_API_URL}/Add_bank_detail.php`;

      if (!phonepay) {
        setphonepayrr("Please enter your Paytm number.");
        return;
      } else {
        setphonepayrr('');
      }

      if (phonepay.length !== 10) {
        setphonepayrr("Please enter a 10-digit Paytm number.");
        return;
      } else {
        setphonepayrr('');
      }

      const newFormData = new FormData();
      newFormData.append("app_id", process.env.REACT_APP_API_ID);
      newFormData.append("userId", user_id);
      newFormData.append("device_id", dev_id);
      newFormData.append("phone_pay", phonepay);
      newFormData.append("type", "phonepay");


      const response = await axios.post(url, newFormData);
      const responseData = response.data;

      const MySwal = withReactContent(Swal);
      if (responseData.success === "1") {
        MySwal.fire({
          title: responseData.message,
          icon: 'success',
          timer: 2500
        }).then((result) => {
          handleCloseModal()
          handleClosePhonePeModal()
        });
      } else {
        MySwal.fire({
          title: responseData.message,
          timer: 2000,
          icon: 'error',
        });
      }
  
    } catch (error) {
      console.error("Error submitting payment details:", error);
    }
  };




  const gooogale = async () => {
    // alert("jhgh")
    try {
      const user_id = localStorage.getItem("userid");
      const dev_id = localStorage.getItem("dev_id");
      const url = `${process.env.REACT_APP_API_URL}/Add_bank_detail.php`;

      if (!Google) {
        setPaytmErrorr("Please enter your Paytm number.");
        return;
      } else {
        setPaytmErrorr('');
      }

      if (Google.length !== 10) {
        setPaytmErrorr("Please enter a 10-digit Paytm number.");
        return;
      } else {
        setPaytmErrorr('');
      }

      const newFormData = new FormData();
      newFormData.append("app_id", process.env.REACT_APP_API_ID);
      newFormData.append("userId", user_id);
      newFormData.append("device_id", dev_id);
      newFormData.append("google_pay", Google);
      newFormData.append("type", "googlepay");


      const response = await axios.post(url, newFormData);
      const responseData = response.data;

      const MySwal = withReactContent(Swal);
      if (responseData.success === "1") {
        MySwal.fire({
          title: responseData.message,
          icon: 'success',
          timer: 2500
        }).then((result) => {
          handleCloseModal()
          handleCloseGooglePayModal()
        });
      } else {
        MySwal.fire({
          title: responseData.message,
          timer: 2000,
          icon: 'error',
        });
      }
  
    } catch (error) {
      console.error("Error submitting payment details:", error);
    }
  };


  const loadUserr = async () => {
    try {
      const user_id = localStorage.getItem("userid");
      const dev_id = localStorage.getItem("dev_id");
      const url = `${process.env.REACT_APP_API_URL}/Add_bank_detail.php`;

      if (!paytm) {
        setPaytmError("Please enter your Paytm number.");
        return;
      } else {
        setPaytmError('');
      }

      if (paytm.length !== 10) {
        setPaytmError("Please enter a 10-digit Paytm number.");
        return;
      } else {
        setPaytmError('');
      }

      const newFormData = new FormData();
      newFormData.append("app_id", process.env.REACT_APP_API_ID);
      newFormData.append("userId", user_id);
      newFormData.append("device_id", dev_id);
      newFormData.append("paytm_number", paytm);
      newFormData.append("type", "paytm");

      const response = await axios.post(url, newFormData);
      const responseData = response.data;

      const MySwal = withReactContent(Swal);
      if (responseData.success === "1") {
        MySwal.fire({
          title: responseData.message,
          icon: 'success',
          timer: 2500
        }).then((result) => {
          handleCloseModal()
        });
      } else {
        MySwal.fire({
          title: responseData.message,
          timer: 2000,
          icon: 'error',
        });
      }
  
    } catch (error) {
      console.error("Error submitting payment details:", error);
    }
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const newGameid = url.searchParams.get('id');

    if (newGameid) {
      setGameid(newGameid);
      localStorage.setItem('gameid', newGameid);
    }
  }, [window.location.href]);

  return (
    <div className='heightall'>
      <div className={`header-top  ${scrolltopdata}`}>
        <Container>
          <Row className="d-flex align-items-center justify-content-between">
            <Col xs={7} md={7} className="d-flex">
              <GiHamburgerMenu className='menuicon text-white' onClick={handleClick} />
              <h6 className='text-center mb-0'>Whitecasino Matka</h6>
            </Col>
            <Col xs={5} md={5} sm={5}>
             
              <div className="d-flex justify-content-end align-items-center">
              <Link to="/Wallet">
                <div className="d-flex align-items-center wallet">
                  <div className="walleticon">
                    <img src={wallet_icon} alt="wallet_icon" width={25} height={25} />
                  </div>
                  <strong>{userData && userData.credit}</strong>
                </div>
                </Link>
                <Link to="/Notification" onClick={handleReload} className="buttonpagebell">
                  <Icon.BellFill className="text-white" />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={open ? 'sidebar is-toggle' : 'sidebar'}>
        <div className="profileimage">
          <Button className="closebtn" onClick={handleClick}>&times;</Button>
          <div className="d-flex align-items-center justify-content-between">
            <div className="profilephoto">
              <div className="imagewhatsapp">
                <img src={profile} alt="" />
              </div>
            </div>
            <div className="profiledetails">
              <h3>{userData && userData.name}</h3>
              <h4><strong>ID : </strong>{userData && userData.mob}</h4>
            </div>
          </div>
        </div>
        <ul className=" menulist">
          {SidebarData.map((val) => (
            <li>
              {val.title === 'Logout' ? (
                <Link to={val.path} onClick={logout} className={val.ownclass}>
                  <div className="iconmenu">{val.icon}</div>
                  <div className="menulistsidebar">
                    {val.title}
                    {val.hinditext}
                  </div>
                </Link>
              ) : val.title === 'Share our App' ? (
                <Link to="#" onClick={val.onClick} className={val.ownclass}>
                  <div className="iconmenu">{val.icon}</div>
                  <div className="menulistsidebar">
                    {val.title}
                    <small> {val.hinditext}</small>
                  </div>
                </Link>
              ) : val.title === 'Paytm UPI' ? (
                <Link to="#" onClick={handleShowModal} className={val.ownclass}>
                  <div className="iconmenu">{val.icon}</div>
                  <div className="menulistsidebar">
                    {val.title}
                    <small> {val.hinditext}</small>
                  </div>
                </Link>
              ) : val.title === 'Google Pay UPI' ? (
                <Link to="#" onClick={handleShowGooglePayModal} className={val.ownclass}>
                  <div className="iconmenu">{val.icon}</div>
                  <div className="menulistsidebar">
                    {val.title}
                    <small> {val.hinditext}</small>
                  </div>
                </Link>
              ) : val.title === 'PhonePe UPI' ? (
                <Link to="#" onClick={handleShowPhonePeModal} className={val.ownclass}>
                  <div className="iconmenu">{val.icon}</div>
                  <div className="menulistsidebar">
                    {val.title}
                    <small> {val.hinditext}</small>
                  </div>
                </Link>
              ) : (
                <Link to={val.path} onClick={handleClick} className={val.ownclass}>
                  <div className="iconmenu">{val.icon}</div>
                  <div className="menulistsidebar">
                    {val.title}
                    <small> {val.hinditext}</small>
                  </div>
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className={`sidebar-overlay ${open == true ? 'active' : ''}`} onClick={handleClick}></div>
      <div onClick={refreshPage} className="refreshbtn">
        <IoMdRefresh />
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>PAYTM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Enter your Paytm Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Paytm Number"
              value={paytm}
              onChange={handleInputChange}
              isInvalid={paytmError}
            />
            <Form.Control.Feedback type="invalid">
              {paytmError}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
          <Button variant="primary" onClick={loadUserr}>Submit</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showGooglePayModal} onHide={handleCloseGooglePayModal}>
      <Modal.Header closeButton>
          <Modal.Title>Google</Modal.Title>
        </Modal.Header>
        <Form.Label>Enter your Google Pay Number</Form.Label>
        <Form.Control
              type="text"
              placeholder="Google Number"
              value={Google}
              onChange={handleInputChanggoogel}
              isInvalid={paytmErrorr}
            />
              <Form.Control.Feedback type="invalid">
              {paytmErrorr}
            </Form.Control.Feedback>

      
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseGooglePayModal}>Close</Button>
          <Button variant="primary" onClick={gooogale}>Submit</Button>

          {/* Add your submit button or action for Google Pay UPI */}
        </Modal.Footer>
      </Modal>

      {/* PhonePe UPI Modal */}
      <Modal show={showPhonePeModal} onHide={handleClosePhonePeModal}>
        <Modal.Header closeButton>
          <Modal.Title>PhonePe UPI</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label>Enter your phonepay Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="phonepay Number"
              value={phonepay}
              onChange={handleInputChangephonepay}
              isInvalid={phonepayrr}
            />
            <Form.Control.Feedback type="invalid">
              {phonepayrr}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
       
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePhonePeModal}>Close</Button>
          <Button variant="primary" onClick={phonepayyyy}>Submit</Button>

          {/* Add your submit button or action for PhonePe UPI */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
