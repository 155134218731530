import React, { useState, useEffect } from 'react';
import rup from "../../assets/img/rup.jpg";
import { MdOutlineCurrencyRupee } from "react-icons/md";

import axios from "axios";
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Form, Spinner } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import nodata from '../../assets/img/nodata.png'

function Winighistory() {

  const [users, setUserss] = useState("")
  const [user, setUsers] = useState("")
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  console.warn(users)
  const [selectedMarket, setSelectedMarket] = useState('1');

  const handleMarketChange = (event) => {
    setSelectedMarket(event.target.value);
  };

  useEffect(() => {
    kalyanGame();
    mainMarket();
  }, [])
  const kalyanGame = async () => {
    setLoading1(true);
    const user_id = localStorage.getItem('userid');
    const dev_id = localStorage.getItem('dev_id');
    let url = `${process.env.REACT_APP_API_URL}/Bit_win_history.php`;
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    formData.append('type', 'starline');
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config).then(function (response) {
      const res = JSON.stringify(response.data.data);
      if (res != undefined) {
        const objectRes = JSON.parse(res);
        setUserss(objectRes);
        console.warn(objectRes);
      }
    })
      .finally(() => {
        // setTimeout(() => {
        setLoading1(false);
        // }, 10004);
      });
  };
  const mainMarket = async () => {
    setLoading(true);
    const user_id = localStorage.getItem('userid');
    const dev_id = localStorage.getItem('dev_id');
    let url = `${process.env.REACT_APP_API_URL}/Bit_win_history.php`;
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    formData.append('type', 'mainMarket');
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config).then(function (response) {
      const res = JSON.stringify(response.data.data);
      if (res != undefined) {
        const objectRes = JSON.parse(res);
        setUsers(objectRes);
        console.warn(objectRes);
      }
    })
      .finally(() => {
        // setTimeout(() => {
        setLoading(false);
        // }, 10004);
      });
  };

  return (
    <>
      <div id='bithistory' className='mainhome wininghistory'>
        <div className="bg-white p-2 round_radius">
          <Form.Select aria-label="Default select example" className='' onChange={handleMarketChange} value={selectedMarket}>
            <option value="1">Main Market</option>
            {/* <option value="2">Star Line Market</option> */}
          </Form.Select>
        </div>

        {/* <div className="bg-white histroywin ">
          <div className="d-flex justify-content-between align-items-center border-bottom-5">
            <div className="wallet_after_before">
              <h4 className="">rajdhani day</h4>
              <ul>
                <li>
                  <h3 className="mb-0">Before Bal <span><MdOutlineCurrencyRupee />228100</span></h3>
                </li>
                <li>
                  <h3 className="mb-0">Bid Number : <span>140,4(Close)</span></h3>
                </li>
                <li>
                  <h3 className="mb-0">Before Bal <span><MdOutlineCurrencyRupee />228100</span></h3>
                </li>
              </ul>
            </div>
            <div className='winamount'>
              <h3>Win<span><MdOutlineCurrencyRupee />1000</span></h3>
            </div>
          </div>
          <p className="mb-0 text-center fw-bold date_time">Date:30-01-2023 01:07 PM</p>
        </div> */}

        {selectedMarket === '1' && (
          <>
            {user && user.length > 0 ? (
              user.map((userData, index) => {
                const sum = parseInt(userData.tr_value) + parseInt(userData.tr_value_updated);
                return (
                  <div className="bg-white histroywin" key={index}>
                    <h4 className="text-center">{userData.market_name}</h4>
                    <div className="d-flex justify-content-between align-items-center border-bottom-5">
                      <div className="wallet_after_before">
                        <ul>
                          <li>
                            <h3 className="mb-0">Before Sum: <span><MdOutlineCurrencyRupee />{sum}</span></h3>
                          </li>
                          <li>
                            <h3 className="mb-0">Bid Number : <span>{userData.pred_num}({userData.status})</span></h3>
                          </li>
                          <li>
                            <h3 className="mb-0">After Bal <span><MdOutlineCurrencyRupee />{userData.tr_value_updated}</span></h3>
                          </li>
                        </ul>
                      </div>
                      <div className='winamount'>
                        <h3>Point<span><MdOutlineCurrencyRupee />{userData.tr_value}</span></h3>
                      </div>
                    </div>
                    <p className="mb-0 text-center fw-bold date_time">Date:{userData.betTime}</p>
                  </div>
                );
              })
            ) : (
              <div className='nodataavailable'>
              <img src={nodata} className='img-fluid'/>
              </div>
            )}
          </>
        )}

        {selectedMarket === '2' && (
          <>
            {users && users.length > 0 ? (
              users.map((userData, index) => {
                const sum = parseInt(userData.tr_value) + parseInt(userData.tr_value_updated);
                return (
                  <div className="bg-white histroywin" key={index}>
                    <h4 className="text-center">{userData.market_name}</h4>
                    <div className="d-flex justify-content-between align-items-center border-bottom-5">
                      <div className="wallet_after_before">
                        <ul>
                          <li>
                            <h3 className="mb-0">Before Sum: <span><MdOutlineCurrencyRupee />{sum}</span></h3>
                          </li>
                          <li>
                            <h3 className="mb-0">Bid Number : <span>{userData.pred_num}({userData.status})</span></h3>
                          </li>
                          <li>
                            <h3 className="mb-0">After Bal <span><MdOutlineCurrencyRupee />{userData.tr_value_updated}</span></h3>
                          </li>
                        </ul>
                      </div>
                      <div className='winamount'>
                        <h3>Point<span><MdOutlineCurrencyRupee />{userData.tr_value}</span></h3>
                      </div>
                    </div>
                    <p className="mb-0 text-center fw-bold date_time">Date:{userData.betTime}</p>
                  </div>
                );
              })
            ) : (
              <div className='nodataavailable'>
              <img src={nodata} className='img-fluid'/>
              </div>
            )}
          </>
        )}



      </div>
      {loading && (

        <div className="spinner-wrapper">
          <div className="loadernew2"></div>
        </div>
      )}
      {loading1 && (

        <div className="spinner-wrapper">
          <div className="loadernew2"></div>
        </div>
      )}
    </>
  )
}

export default Winighistory