import React,{useState} from 'react';
import * as Icon from "react-bootstrap-icons";
import Home from '../../assets/img/sidebarimage/home.png'
import user from '../../assets/img/sidebarimage/user.png'
import wallet from '../../assets/img/sidebarimage/wallet.png'
import add_funds from '../../assets/img/sidebarimage/add_funds.png'
import withdrawal from '../../assets/img/sidebarimage/withdrawal.png'
import transfer_points from '../../assets/img/sidebarimage/transfer_points.png'
import trophy from '../../assets/img/sidebarimage/trophy.png'
import bid_history from '../../assets/img/sidebarimage/bid_history.png'
import bank from '../../assets/img/sidebarimage/bank.png'
import paytm from '../../assets/img/sidebarimage/paytm.png'
import phonepe from '../../assets/img/sidebarimage/phonepe.png'
import gpay from '../../assets/img/sidebarimage/gpay.png'
import game_rates from '../../assets/img/sidebarimage/game_rates.png'
import pay_game from '../../assets/img/sidebarimage/pay_game.png'
import notice from '../../assets/img/sidebarimage/notice.png'
import contact_us from '../../assets/img/sidebarimage/contact_us.png'
import share from '../../assets/img/sidebarimage/share.png'
import lock from '../../assets/img/sidebarimage/lock.png'
import log_out from '../../assets/img/sidebarimage/log_out.png'
const user_id = localStorage.getItem("userid");
const handleShare = () => {
  if (navigator.share) {
    navigator.share({
      title: "Check out this app!",
      url: "https://dlmatka.com/API/public/api",
    })
      .then(() => console.log('Shared successfully'))
      .catch((error) => console.error('Error sharing:', error));
  } else {
    const unsupportedBrowserMessage = `
      Sharing is not supported in this browser.
      To share, please manually copy and paste the following link:
      https://dlmatka.com/API/public/api
    `;
    alert(unsupportedBrowserMessage);
  }
};

const Sidebardata = [
  {
    title: "Home",
    path: "/",
    icon: <img src={Home} width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },

  {
    title: "User Details",
    path: "/Profile",
    icon: <img src={user} width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Wallet",
    path: "/Wallet",
    // path :"/",
    icon:<img src={wallet} width={25} height={25}/> ,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Add Funds",
    path: "/AddPayment",
    icon:<img src={add_funds} width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Withdraw Fund",
    path: "/WithdrawAmount",
    icon: <img src={withdrawal} width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },

  {
    title: "Transfer Points",
    path: "/Transferpoints",
    icon:<img src={transfer_points} width={25} height={25}/> ,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Win History",
    path: "/Win-History",
    icon: <img src={trophy} width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Bid History",
    path: "/Bid-History",
    icon: <img src={bid_history} width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Wallet History",
    path: "/Wallet-history",
    icon: <img src={wallet} width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Bank Details",
    path: "/Add-Bank-Detais",
    icon: <img src={bank} width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Paytm UPI",
    path: "/",
    icon: <img src={paytm} width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
    
  },
  {
    title: "Google Pay UPI",
    path: "/",
    icon: <img src={gpay} width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "PhonePe UPI",
    path: "/",
    icon: <img src={phonepe} width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Main Game Rates",
    path: "/Market-ret",
    icon: <img src={game_rates} width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  // {
  //   title: "Starline Games Rates",
  //   path: "/Starlinegamerate",
  //   icon: <img src={game_rates} width={25} height={25}/>,
  //   ownclass: "d-flex align-items-center link-page navlink-design",
  // },
  {
    title: "How to Play",
    path: "/How-to-play",
    icon: <img src={pay_game} width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Notice",
    path: "/Notice",
    icon: <img src={notice} width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Contact Us",
    path: "/Contactus",
    icon: <img src={contact_us } width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Share With Friends",
    // path: "/",
    icon: <img src={share} width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Rate App",
    // path: "/",
    icon: <img src={game_rates} width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Change Password",
    path: "/Changepassword",
    icon: <img src={lock} width={25} height={25}/> ,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Logout",
    path: "/",
    icon: <img src={log_out} width={25} height={25}/>,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  


  //   {
  //     title:" History",
  //     path:"/History",
  //     icon:<Icon.ClockHistory/>,
  //     ownclass:"d-flex align-items-center link-page navlink-design",
  // },
  //   {
  //     title:"Whatsapp Group Join ",
  //     path:"https://api.whatsapp.com/send?phone=6367529290 ",
  //     icon:<Icon.Whatsapp/>,
  //     ownclass:"d-flex align-items-center link-page navlink-design",
  // },


  //   {
  //     title:"Result History",
  //     path:"/Resulthistory",
  //     icon:<Icon.HourglassSplit/>,
  //     ownclass:"d-flex align-items-center link-page navlink-design",
  // },
  //   {
  //     title:"Terms And Condtion",
  //     path:"/Termsandcondition",
  //     icon:<Icon.JournalCheck/>,
  //     ownclass:"d-flex align-items-center link-page navlink-design",
  // },

 


  //   {
  //     title:"Game Posting",
  //     path:"/Gameposting",
  //     icon:<Icon.Chat/>,
  //     ownclass:"d-flex align-items-center link-page navlink-design",
  // },
  //   {
  //     title:"Rate Our App",
  //     path:"https://play.google.com/store/apps/details?id=com.babaji.matka",
  //    icon:<Icon.Phone/>,
  //     ownclass:"d-flex align-items-center link-page navlink-design",
  // },

  


];
export default Sidebardata;